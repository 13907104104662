<template>
    <div class="main-page">
        <section class="page-section">
            <div class="container">
                <div class="grid">
                    <div class="col-12 md:col-12 xl:col-12 sm:col-12 xs:col-12 comp-grid">
                        <div>
                            <form ref="observer" tag="form" @submit.prevent="validateForm()" @reset="resetForm">
                                <div>
                                    <table class="p-datatable-table" style="width: 100%">
                                        <tbody>
                                            <tr v-for="(formData, row) in arrFormData" :key="row" class="input-row">
                                                <div class="card nice-shadow-5">
                                                    <div class="grid">
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("name") }} *
                                                            </div>
                                                            <div class="">
                                                                <InputText ref="ctrlname" v-model.trim="formData.name"
                                                                    :label="$t('name')" type="text"
                                                                    :placeholder="$t('name')" class="w-full"
                                                                    :class="getErrorClass(row, 'name')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("position") }} *
                                                            </div>
                                                            <div class="">
                                                                <InputText ref="ctrlposition"
                                                                    v-model.trim="formData.position" :label="$t('position')"
                                                                    type="text" :placeholder="$t('position')" class="w-full"
                                                                    :class="getErrorClass(row, 'position')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-12">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("institution_or_organisation") }} *
                                                            </div>
                                                            <div class="">
                                                                <InputText ref="ctrlinstitution"
                                                                    v-model.trim="formData.institution"
                                                                    :label="$t('institution_or_organisation')" type="text"
                                                                    :placeholder="$t('institution_or_organisation')" class="w-full"
                                                                    :class="getErrorClass(row, 'institution')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("email") }} *
                                                            </div>
                                                            <div class="">
                                                                <InputText ref="ctrlemail" v-model.trim="formData.email"
                                                                    :label="$t('email')" type="text"
                                                                    :placeholder="$t('email')" class="w-full"
                                                                    :class="getErrorClass(row, 'email')">
                                                                </InputText>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 md:col-6">
                                                            <div class="mb-2 font-bold text-sm">
                                                                {{ $t("phone_number") }}
                                                            </div>
                                                            <div class="">
                                                                <InputText ref="ctrlphone" v-model.trim="formData.phone"
                                                                    :label="$t('phone_number')" type="text" maxlength="12" minlength="8"
                                                                    :placeholder="$t('phone_number')" class="w-full" v-tooltip.top.focus="'Eg: 0209013836'"
                                                                    :class="getErrorClass(row, 'phone')">
                                                                </InputText>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="showSubmitButton" class="text-center q-my-md">
                                    <Button type="submit" :label="$t('submit')" icon="pi pi-send" :loading="saving" />
                                </div>
                            </form>
                            <slot :submit="submit" :saving="saving"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: "pgRecommendationLettersPage",
    components: {},
    mixins: [PageMixin, AddPageMixin],
    props: {
        pageName: {
            type: String,
            default: "pgRecommendationLettersPage",
        },
        maxRowCount: {
            type: Number,
            default: 1,
        },
        minRowCount: {
            type: Number,
            default: 1,
        },
        recommendationLetters: { type: String, default: "[]" },
        dobYear: {
            type: Number,
            default: 1950,
        },
    },
    data() {
        return {
            arrFormData: [
                {
                    name: "",
                    position: "",
                    institution: "",
                    phone: "",
                    email: "",
                },
            ],
            other: "OTHER",
            yes: "Yes",
            no: "No"
        };
    },
    validations() {
        let arrFormData = {
            $each: helpers.forEach({
                name: { required },
                position: { required },
                institution: { required },
                email: { required },
                phone: {},
            }),
        };
        return { arrFormData };
    },
    computed: {
        pageTitle: {
            get: function () {
                return this.$t("institution_attended");
            },
        },
    },
    methods: {
        ...mapActions("applicants", ["updateAcademicData"]),
        validateForm() {
            this.submitted = true;
            const isFormValid = !this.v$.$invalid;
            return isFormValid;
        },
        addFormRow() {
            let newFormData = {
                name: "",
                position: "",
                institution: "",
                phone: "",
                email: "",
            };
            this.arrFormData.push(newFormData);
        },
        removeFormRow(index) {
            this.arrFormData.splice(index, 1);
        },
        resetForm() {
            this.arrFormData = [
                {
                    name: "",
                    position: "",
                    institution: "",
                    phone: "",
                    email: "",
                },
            ];
            this.updateFormData();
        },
        getErrorClass(row, field) {
            let errors = this.v$.arrFormData.$each.$response.$errors[row][field];
            return { "p-invalid": errors.length && this.submitted };
        },
        getRecommendationLetters() {
            if (this.recommendationLetters) {
                let recommendationLetters = JSON.parse(this.recommendationLetters)
                this.arrFormData = recommendationLetters;
            }
            if (this.arrFormData.length < 1) {
                this.addFormRow();
            }
            if (this.minRowCount > 1 && this.arrFormData.length < this.minRowCount) {
                this.addFormRow();
            }
        },
    },
    watch: {},
    async mounted() {
        this.getRecommendationLetters();
    },
    async created() { },
};
</script>
<style scoped></style>